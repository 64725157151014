import "./css/universal-components/default-page.css";
import * as React from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import { Navbar } from "./layouts/steelworks/footer-header/header";
import {MainPage} from "./layouts/steelworks/main-page/main-page";
import ContactForm from "./layouts/steelworks/contact/contact-form";
import ContactInfo from "./layouts/steelworks/contact/contact-info";
import Footer from "./layouts/steelworks/footer-header/footer";
import GitterboxContainers from "./layouts/steelworks/products/gitterbox-containers";
import GlobalMainPage from "./layouts/global-main-page/global-main-page";
import {HotelMainPage} from "./layouts/hotel-industry/main-page/main-page";
import {HotelNavbar} from "./layouts/hotel-industry/footer-header/header";

export const App = () => {
    const location = useLocation();

    return (
        <div className='background'>
            <div className='blocking-div'></div>
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
            <div className='blocking-div'></div>
        </div>
    );
};
