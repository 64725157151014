import React, { useEffect } from "react";
import "../../../css/steelworks/main-page/main-page.css";
import forkImage from "../../../images/fork.jpg";
import weldImage from "../../../images/spaw.jpg";
import plch from "../../../images/plch.jpg";
import plch1 from "../../../images/plch1.jpg";
import plch2 from "../../../images/plch2.jpg";
import ProductCard from "../universal-components/product-card";
import InfoCard from "../universal-components/info-card";
import Button from "../universal-components/button";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import plch3 from "../../../images/plch3.jpg";
import {NavLink} from "react-router-dom";
import stalownia from "../../../images/stalownia.png";

export const MainPage = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        const storedLang = window.localStorage.getItem("lang");
        if (storedLang) {
            i18n.changeLanguage(storedLang);
        }
    }, [i18n]);

    return (
        <div className='main-page'>
            <div className="hero-section">
                <div className="hero-content">
                    <NavLink className="logo" to="/">
                        <img className="logo" src={stalownia} alt="Logo"/>
                    </NavLink>

                </div>
            </div>
            <h1 className="clash-font">Page in construction, come back later!</h1>

            <div className={"section-break"}></div>
        </div>
    );
};
